import moment from 'moment'
import { EmploymentStatuses } from './enums'

const defaultSasria = 2.02

export class Helpers {
  constructor() { }

  static getInStorage = async (key, islocalStorage) => {
    try {
      // if is local storage
      if (islocalStorage) {
        return await localStorage.getItem(`@pineapple:${key}`)
      } else {
        // if session storage
        return await sessionStorage.getItem(`@pineapple:${key}`)
      }
    } catch (e) {
      console.log('getInStorage error', e)
    }
  }

  static setInStorage = async (key, val, islocalStorage) => {
    try {
      // if is local storage
      if (islocalStorage) {
        await localStorage.setItem(`@pineapple:${key}`, val)
      } else {
        // if session storage
        await sessionStorage.setItem(`@pineapple:${key}`, val)
      }
    } catch (e) {
      console.log('setInStorage error', e)
    }
  }

  static removeFromStorage = async (key, isLocalStorage = false) => {
    try {
      if (isLocalStorage) {
        return await localStorage.removeItem(`@pineapple:${key}`)
      } else {
        return await sessionStorage.removeItem(`@pineapple:${key}`)
      }
    } catch (e) {
      console.log('removeFromStorage error', e)
    }
  }

  /**
   * Set the logged in user
   */
  static setAuthUser = async (user) => {
    await Helpers.setInStorage('user', JSON.stringify(user))
  }

  /**
   * Get the logged in user
   */
  static getAuthUser = async () => {
    const userStr = await Helpers.getInStorage('user')
    if (userStr) {
      return JSON.parse(userStr)
    }
  }

  static getAuthUserId = async () => {
    const userStr = await Helpers.getInStorage('user')

    if (userStr) {
      const user = JSON.parse(userStr)
      return user.id || user._id
    }
  }

  static getSavedVehicle = async () => {
    const str = await Helpers.getInStorage('vehicle');
  
    if (str && str.length > 0 && str !== 'undefined') {
      return JSON.parse(str)
    }

    return null
  }
  
  static getSavedVehicles = async () => {
    try {
      const str = await Helpers.getInStorage('vehicles')
      return JSON.parse(str)
    } catch (e) {
      return []
    }
  }

  static removeSpecialCharacters(inputString) {
    const regex = /[^a-zA-Z0-9-' ]/g
    const result = inputString.replace(regex, '')
    return result
  }

  static getSavedRegularDriver = async () => {
    const str = await Helpers.getInStorage('regularDriver')

    if (str) {
      return JSON.parse(str)
    }
  }

  static setCurrentReferrerLink =  async () => {
    await Helpers.setInStorage('referrerUrl', document.referrer);
  }

  static getSavedOwner = async () => {
    const str = await Helpers.getInStorage('owner')

    if (str) {
      return JSON.parse(str)
    }
  }

  static clearStorage = () => {
    sessionStorage.clear()
  }

  static querystring = (json) => {
    const str = Object.keys(json)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
      )
      .join('&')

    return str
  }

  static replaceAllInString = (target, search, replacement) => {
    return target.replace(new RegExp(search, 'g'), replacement)
  }

  static capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static destructureNightSecurity = (nightSecurity) => {
    // night time security
    let vehicleNightTimeAccessControl = 'U';
    let vehicleNightTimeSecurityGuard = 'U';

    switch (nightSecurity) {
      case 'ACSG':
        vehicleNightTimeAccessControl = 'Y';
        vehicleNightTimeSecurityGuard = 'Y';
        break;
      case 'ACNSG':
        vehicleNightTimeAccessControl = 'Y';
        vehicleNightTimeSecurityGuard = 'N';
        break;
      case 'SG':
        vehicleNightTimeAccessControl = 'N';
        vehicleNightTimeSecurityGuard = 'Y';
        break;
      case 'NACNSG':
        vehicleNightTimeAccessControl = 'N';
        vehicleNightTimeSecurityGuard = 'N';
        break;
    }
    return { vehicleNightTimeAccessControl, vehicleNightTimeSecurityGuard };
  };

  // removes special characters and emoji from a string
  static sanitiseText = (text) => {
    return text.replace(/[^a-zA-Z0-9 _'-]/gi, '')
  }

  static parseSAIdNo = (idNumber) => {
    if (!idNumber || idNumber.length != 13) {
      return false
    }

    // today's date
    const today = moment()

    // assume you're born in 20th century
    let yr_str = `19${idNumber.substring(0, 2)}`
    const m_str = idNumber.substring(2, 4)
    const d_str = idNumber.substring(4, 6)

    // Handle edgecase of user born on 29 Feb 2000
    if (idNumber.substring(0,6) === '000229') {
      yr_str = `20${idNumber.substring(0, 2)}`
    }

    // date from above info
    const tempDate = moment(`${yr_str}-${m_str}-${d_str}`, 'YYYY-MM-DD')
    
    const id_date = tempDate.date()
    const id_month = tempDate.month() + 1
    let year = tempDate.year()
    
    // calculate assumed age
    let age = today.format('YYYY') - tempDate.format('YYYY')
    
    // assume you're always younger than 100
    while (age > 100) {
      age -= 100
      tempDate.add(100, 'y')
    }

    // update year
    year = tempDate.format('YYYY')

    if (
      year.substring(2, 4) !== idNumber.substring(0, 2) ||
      parseInt(id_month, 10) !== parseInt(idNumber.substring(2, 4), 10) ||
      parseInt(id_date, 10) !== parseInt(idNumber.substring(4, 6), 10)
    ) {
      return false
    }

    // return dictionary
    return {
      age,
      day: id_date,
      month: id_month,
      year,
      gender:
        parseInt(idNumber.substring(6, 10), 10) < 5000 ? 'female' : 'male',
    }
  }

  static getEmploymentStatuses = (zaId) => {
    const parsedZaId = Helpers.parseSAIdNo(zaId)

    // za ID could not be parsed - return default values
    if (!parsedZaId || parsedZaId.age >= 55) {
      return EmploymentStatuses.map(status => ({
        label: status.description,
        value: status.value
      }))
    } else {
      // younger than 55
      return EmploymentStatuses.filter((status) => {
        if (['Employed', 'Unemployed'].indexOf(status.value) < 0) {
          return false
        }

        return true
      })
        .map(status => ({
          label: status.description,
          value: status.value
        }))
    }
  }

  static getBasketPremiumTotals = (basket) => {
    const premiumTotals = basket.reduce((totals, item) => {
      totals.lowMileage = totals.lowMileage + item.low_mileage_premium + (item.credit_shortfall_premium || 0) + defaultSasria
      totals.comprehensive = totals.comprehensive + item.comprehensive_premium + (item.credit_shortfall_premium || 0) + defaultSasria
      return totals
    }, { lowMileage: 0, comprehensive: 0 })
    return premiumTotals
  }

  static parsePreviousLossesAnswers = (answers) => {
    const losses = Object.values(answers)
      .filter((loss) => {
        if (
          !loss.incident_year
          || loss.incident_year.length !== 4
          || loss.incident_year > parseInt(moment().format('YYYY'), 10)
        ) {
          return false;
        }
        return true;
      })
      .map((loss) => {
        const thisYearStr = moment().format('YYYY')
        let lossDate
  
        if (loss.incident_year === thisYearStr) {
          const daysDiff = moment().diff(moment(`${thisYearStr}-01-01`), 'd')
          const halfDaysDiff = Math.round(daysDiff / 2)
  
          lossDate = moment().subtract(halfDaysDiff, 'd').format('YYYY-MM-DD')
        } else {
          const dateStr = `${loss.incident_year}-06-30`
          const date = new Date(dateStr)
          const momentDate = moment(date)
          lossDate = momentDate.format('YYYY-MM-DD')
        }
  
        return ({
          selected: true,
          editing: false,
          display: true,
          prevLossDate: lossDate,
          prevLossCategory: loss.loss_type,
          prevLossSection: 'STMTR',
          hasClaimBeenRejected: loss.claim_rejected === 'Y',
          prevLossDetails: loss.incident_description,
          prevLossAmount: parseInt(loss.loss_value, 10),
          claimRejectionReason: loss.claim_rejected === 'Y' ? loss.claim_rejected_reason : undefined,
        })
      })
  
    return losses
  }

  static parseClaimDates = (claimDates = []) => {
    const lossAnswers = Object.values(claimDates).map((claim) => {
      const year = new Date(claim.prevLossDate).getFullYear()

      return {
        claim_rejected: claim.hasClaimBeenRejected ? 'Y' : 'N',
        claim_rejected_reason: claim.hasClaimBeenRejected ? claim.claimRejectionReason : '',
        incident_description: claim.prevLossDetails,
        incident_year: year.toString(),
        loss_type: claim.prevLossCategory,
        loss_value: claim.prevLossAmount.toString(),
      }
    })

    return lossAnswers
  }

  static getAddressLine = (address) => {
    const { riskscapeAddress } = address

    // check riskscape
    if (riskscapeAddress) {
      const { property_details, address_candidate } = riskscapeAddress

      if (address_candidate) {
        return address_candidate
      } else if (property_details) {
        const {
          full_address,
          short_address,
          suburb,
          province,
          postal_code,
          municipality,
        } = property_details

        if (full_address) {
          return full_address
        } else if (short_address) {
          return `${short_address}, ${suburb}, ${municipality}, ${province} ${postal_code}`
        }
      }
    }
  }

  static formatUserInfo = (agent, user, dealership) => {
    // user data
    const data = {
      platform: 'web',
      date: new Date()
    }

    if (agent) {
      // add agent data
      Object.assign(data, {
        entity: agent.type === 'retentions' ? 'retentions' : 'agent',
        email: agent.email
      })
    } else if (dealership) {
      // save partner email
      Object.assign(data, {
        entity: 'partner',
        email: dealership.email
      })
    } else {
      // add user data
      Object.assign(data, {
        entity: 'user',
        email: user.email
      })
    }
    return data
  }

  static hideMobileViewProgressIndicator = () => {
    // this logic only triggers on mobile screens once the progress tracker snaps to the bottom
    if (window.screen.width > 468) return

    // this condition checks if you have reached the bottom of the page
    if ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 250)) {
      document.querySelectorAll('.progress-step-indicator-data-wrapper').forEach((element) => {
        element.style.display = 'none'
      })
    } else {
      document.querySelectorAll('.progress-step-indicator-data-wrapper').forEach((element) => {
        element.style.display = 'inherit'
      })
    }
  }

  static addDataLayer = async (data) => {
    const isSalesAgent = await this.getInStorage('sales_agent', true);
    const ucid = data.ucid || (await this.getInStorage('ucid', true));
    const uuid = data.uuid || (await this.getAuthUserId());
    const pid = data.pid || await this.getInStorage('pid', true);

    Object.assign(data, {
      product: 'car-insurance',
      userType: isSalesAgent ? 'agent': 'customer',
      uuid,
      ucid,
      pid
    });
    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }

  static chatBotScript = (character) => {
    // in the future, the scripts for other chatbot characters will live here
    const scripts = {
      'Ntsundeni Mudzunga' : {
        GetStarted_General_Greet: 'Hi, I’m Ntsundeni 👋. Let’s get your car insured...',
        GetStarted_FirstName_Greet: 'Great to meet you ${dynamicWord}. I hope you are having a great day!',
        VehicleDetails_Start: 'Don’t be shy, tell us more about the car you are driving by filling in the details below...',
        VehicleDetails_CarMake: 'Oooh! ${dynamicWord}. We see you ${userFirstName} 😉.',
        VehicleDetails_Parking: 'Cool! Tell me ${userFirstName}, where do you park your car?',
        VehicleDetails_Search_Reg: 'Please wait while we retrieve your details from TransUnion...',
        VehicleDetails_Search_Reg_Success: 'All done! Are the details below correct?',
        VehicleDetails_Search_Reg_Error: 'Oops, we could not find your details on TransUnion but please feel free to provide them to us below...',
        VehicleDetails_Sleep_Passive: '${dynamicWord}? No worries, you’ll let us know when you get there 👍.',
        VehicleDetails_Sleep_Active: '${dynamicWord}? NICE, we like it 👍.',
        VehicleDetails_Progress_Update_A: 'Wow ${userFirstName}. Look at you go, keep it up!',
        VehicleDetails_Progress_Update_B: '${dynamicWord}? That’s perfect ${userFirstName}✨!',
        VehicleDetails_Owner_status: 'Oh, ${dynamicWord} you say? Look at you go 😉!',
        VehicleDetails_Progress_Update_C: 'Nearly there ${userFirstName}, hang in there✌️!',
        PersonalDetails_Start: 'We just need a few more personal details ${userFirstName}...',
        PersonalDetails_License_Date: 'Oooh, that’s a good date ${userFirstName}! 👌',
        PersonalDetails_Progress_Update_A: 'Step 3/3! Let’s get you your quote, ${userFirstName}!',
        PersonalDetails_Complete: 'Click “Complete” to see your quote 🔥',
        startPromt: 'Please click on the "Start" button to begin!',
        proceedPromt: 'Please click on the "Proceed" button to move forward.',
        completePromt: 'Please click on the "Complete" button to move forward.',
        default: 'Please tell us as much detail as possible in order for us to give you the best price.'
      }
    }
    return scripts[character]
  }

  static chatBotInteraction = async (step, chatBotCharacter, dynamicWord) => {
    // get the current logged-in user
    let currentUser = await Helpers.getAuthUser()
    let currentUserFirstName = currentUser && currentUser.first_name
    // get the appropriate script line
    let currentStepScript = Helpers.chatBotScript(chatBotCharacter)[step]
    if (currentStepScript) {
    // return the appropriate script line
    return currentStepScript.replace('${dynamicWord}', dynamicWord).replace('${userFirstName}', currentUserFirstName)
    } else if (Helpers.chatBotScript(chatBotCharacter)) {
      // character fallback text
      return Helpers.chatBotScript(chatBotCharacter).default
    } else {
      // general fallback text
      return 'Welcome to Pineapple, we hope you enjoy the experience.'
    }
  }

  static displayReg = (registration) => {
    let regStr = registration
    if (registration && registration.length > 10) {
      regStr = registration.substring(0, 10) + '...'
    }
    return regStr
  }

  static displayMake = (make) => {
    let makeStr = make
    if (make && make.length > 13) {
      makeStr = make.substring(0, 13) + '...'
    }
    return makeStr
  }

  static formatAmount = (amount) => {
    if (amount >= 1000000) {
      return (amount / 1000000).toFixed(1) + 'm';
    } else if (amount >= 1000) {
      return (amount / 1000).toFixed(1) + 'k';
    } else {
      return amount.toString();
    }
  }

  static removeVehicleFromStorage = async (condition) => {
    try{
      if (condition) {
        // remove vehicle from session storage so that a new vehicle can be added by user when navigating to the dashboard
        await Helpers.removeFromStorage('vehicle')
        // set numberPlate to empty string to remove previous vehicle number plate from populating number plate input
        await Helpers.removeFromStorage('numberPlate')
        await Helpers.removeFromStorage('hasNoNumberPlate')
      }
    }
    catch(error){
      console.log('removeVehicleFromStorage error', error)
    }
  }



  static getUserMetaData = (previousUrl)=>{
    const userCreatedViaWeb = true
    const previousURL = previousUrl || document.referrer;

    const getBrowserName = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.includes('edg') || userAgent.includes('edge')) return 'Microsoft Edge';
      if (userAgent.includes('msie') || userAgent.includes('trident')) return 'Internet Explorer';
      if (userAgent.includes('opera') || userAgent.includes('opr')) return 'Opera';
      if (navigator.brave) return 'Brave';
      if (userAgent.includes('chrome')) return 'Google Chrome';
      if (userAgent.includes('safari')) return 'Apple Safari';
      if (userAgent.includes('firefox')) return 'Mozilla Firefox';
      return 'Unknown Browser';
    };

    const getDeviceMakeAndModel = () => {
      const userAgent = navigator.userAgent;

      // Extracting device make and model from the user agent string
      const match = userAgent.match(/\(([^)]+)\)/);
      return match ? match[1] : 'Unknown';
    };

    const browserInfo = {
      name: getBrowserName(),
      userAgent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform,
      appName: navigator.appName,
      appVersion: navigator.appVersion,
    };

    const operatingSystem = (() => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.includes('windows')) return 'Windows';
      if (userAgent.includes('mac')) return 'Mac';
      if (userAgent.includes('android')) return 'Android';
      if (userAgent.includes('iphone') || userAgent.includes('ipad')) return 'iOS';
      return 'Unknown';
    })();

    const deviceInfo = {
      makeAndModel: getDeviceMakeAndModel(),
    };

    return {user_agent: {
      userCreatedViaWeb,
      browserInfo,
      operatingSystem,
      deviceInfo,
      previousURL
    }}
  }

  static calculateProRata = (commencementDay, strikeDay, basketComprehensiveTotal, numberOfVehiclesInBasket) => {
    const today = moment().startOf('day')
    const startOfMonth = moment(today).startOf('month')
    strikeDay = parseInt(strikeDay, 10)
    let proRataDays

    // Get the total days in the current month
    const daysInMonth = startOfMonth.daysInMonth()
    // Validate that both commencementDay and strikeDay are within the valid range
    if (commencementDay > daysInMonth || strikeDay > 31) {
      return `Invalid date: Commencement day or Strike day exceeds the days in this month (${daysInMonth} days).`
    }
    if (strikeDay >= commencementDay) {
      proRataDays = strikeDay - commencementDay
    } else {
      const daysLeftInCurrentMonth = daysInMonth - commencementDay
      // Pro-rata days includes both the remainder of the current month and the strikeDay in the next month
      proRataDays = daysLeftInCurrentMonth + strikeDay
    }

    // Get the total number of days in the month (for pro-rata calculation)
    const daysInSelectedMonth = startOfMonth.daysInMonth()

    // Calculate sasria total sasria amount
    const sasriaAmount = defaultSasria * numberOfVehiclesInBasket
    
    // Calculate the pro-rata amount
    const proRataWithoutSasria = ((basketComprehensiveTotal - sasriaAmount) / daysInSelectedMonth) * proRataDays
    const sasriaProRata = (sasriaAmount /daysInSelectedMonth) * proRataDays
    const proRata = proRataWithoutSasria + sasriaProRata
    return proRata.toFixed(2)
  }


  static shouldUpdateUserDetails = (userDetails) => {
    // If not external lead source
    if (!userDetails || !userDetails.external_lead_source) {
      return false
    }

    const regex = new RegExp('noemail|^[\+27]|' + userDetails.contact_number, 'i')
    const { first_name, last_name, email } = userDetails

    // Check for missing first name, last name, or email
    if (regex.test(email) || first_name === 'Nofirstname' || last_name === 'Nolastname') {
      return true
    }

    return false
  }

  static RenderClauses = (vehicleStatus, vehicleTracking, clauses) => {

    let RenderClause = []

    length = clauses.length

    const FindClauseIndex = (clauses, subText) => {
      return clauses.findIndex(str => str.includes(subText))
    }

    const preInspectionClauseSubtext = 'You must complete your vehicle\'s pre-inspection on the Pineapple app for your cover to commence.'
    const preInspectionClauseIndex = FindClauseIndex(clauses, preInspectionClauseSubtext)

    const noTrackingDeviceClauseSubText = 'As there is currently no tracking device installed in your vehicle'
    const noTrackingDeviceClauseIndex = FindClauseIndex(clauses, noTrackingDeviceClauseSubText)

    const presentTrackingDeviceClauseSubText = 'You confirmed that the vehicle is fitted with a tracking device that meets our approval and policy requirement'
    const presentTrackingDeviceClauseIndex = FindClauseIndex(clauses, presentTrackingDeviceClauseSubText)

    const stolenVehicleRecoveryClauseSubText = "It is a policy requirement for you to have a tracking device with Stolen Vehicle Recovery"
    const stolenVehicleRecoveryClauseIndex = FindClauseIndex(clauses, stolenVehicleRecoveryClauseSubText)

    // Handle preInspectionClause
    if (vehicleStatus === 'Used' || vehicleStatus === 'SecondHand') {
      if (preInspectionClauseIndex !== -1) {
          RenderClause.push(clauses[preInspectionClauseIndex])
      }
  }

    // Handle noTrackingDeviceClause
    if (vehicleTracking == 'N' && noTrackingDeviceClauseIndex !== -1) {
      RenderClause.push(clauses[noTrackingDeviceClauseIndex])
    } else if (presentTrackingDeviceClauseIndex !== -1) {
      RenderClause.push(clauses[presentTrackingDeviceClauseIndex])
    }

    // Handle stolenVehicleRecoveryClause
    if (
      stolenVehicleRecoveryClauseIndex !== -1 &&
      !RenderClause.includes(clauses[stolenVehicleRecoveryClauseIndex])
    ) {
      RenderClause.push(clauses[stolenVehicleRecoveryClauseIndex]);
    }

    return RenderClause;
  }

  static extractUserSubset = (user) => {
    const keys = [
      '_id',
      'first_name',
      'last_name',
      'email',
      'contact_number',
      'users_invite_code',
      'sms_sent_today',
      'contact_number_verified',
      'intercom_contact_id',
      'motor_policy_number',
      'motor_quote_number',
      'location',
    ]
    
    return keys.reduce((acc, key) => {
      if (user[key] !== undefined) {
        acc[key] = user[key]
      }
      return acc
    }, {})

  }
}


